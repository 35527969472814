<!--
 * @Autor: oops.liu
 商品组件
-->
<template>
  <div
    class="goods_item"
    :style="{ width: width }"
    @click="to(info)"
    v-if="info"
  >
    <div class="cover_box" :style="{ height: height }">
      <img class="cover" :src="info.picUrls[0]" />
    </div>
    <div class="goods_info">
      <div class="goods_name line2">
        <p class="tags" v-if="info.tags">新品</p>
        {{ info.name }}
      </div>
      <div class="price_box flex_sb">
        <div class="price">
          <span class="tags">￥</span
          ><span class="now_price">{{ info.priceDown }}</span>
        </div>
      </div>
      <div class="slot_box">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    width: {
      type: String,
      default: "49%",
    },

    height: {
      type: String,
      default: "147px",
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    to(id) {
      this.$emit("jump", id);
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.goods_item {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 7px;

  .cover_box {
    width: 100%;

    .cover {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .goods_info {
    box-sizing: border-box;
    padding: 7px;

    .goods_name {
      font-size: 12px;
      line-height: 1.2em;
      height: 2.3em;
      .tags {
        display: inline-block;
        font-size: 12px;
        border-radius: 6px;
        background: #ff9900;
        color: #fff;
        padding: 2px 5px;
        transform: scale(0.7);
        transform-origin: 0 50%;
      }
    }

    .price_box {
      padding: 7px 0;

      .price {
        color: #e31802;

        .tags {
          font-size: 12px;
        }

        .now_price {
          font-size: 15px;
          font-weight: bold;
        }
      }

      .icon {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        overflow: hidden;
        background: #e31802;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}
</style>
